import React, { useEffect, useReducer, useRef, useState } from 'react';
import './css/ClientLogin.css';
import './css/RegistrationForm.css'
import axios from 'axios';
import { Redirect ,Link,useHistory} from 'react-router-dom';
import titleLogo from './Images/topbatLogo.png';
import capcha from './Images/captcha.png';
import refressCaptch from './Images/refresh.png';
import secondImage from './Images/awardImage.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card,Modal} from 'react-bootstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import SideBar from './sidebar/Sidebar';


class HomePage extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {


        }
    }


    render()
    {
        return (

            <div>

                    <SideBar />
                <h1>Hello World </h1>

            </div>




        )
    }
}


export default HomePage;