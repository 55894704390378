import React, { useEffect, useReducer, useRef, useState } from 'react';

import axios from 'axios';
import { Redirect ,Link,useHistory} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card,Modal} from 'react-bootstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import SideBar from './sidebar/Sidebar';


class CompanyHome extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {

            loginId : true,
        }

        let token  = sessionStorage.getItem("companyName");

        if(token == null || token == "" || token == undefined)
        {
            this.state.loginId = false;
        }
    }


    render()
    {

        if(this.state.loginId === false)
        {
            return <Redirect to="/companyLogin" />
        }

        
        return (

            <div>

                    <SideBar />
                <h1>Company Home Page </h1>

            </div>




        )
    }
}


export default CompanyHome;