import React, { useEffect, useReducer, useRef, useState } from 'react';
import './css/ClientLogin.css';
import './css/RegistrationForm.css'
import axios from 'axios';
import { Redirect ,Link,useHistory} from 'react-router-dom';
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import 'react-notifications/lib/notifications.css';
import titleLogo from './Images/topbatLogo.png';
import capcha from './Images/captcha.png';
import refressCaptch from './Images/refresh.png';
import secondImage from './Images/awardImage.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card,Modal} from 'react-bootstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const AdminLogin = ()=>
{

    // const [email ,setEmail] = useState("");
    // const [password , setPassword] = useState("");

    const email = useRef(null);
    const password = useRef(null);
    const history = useHistory();

    const  captchaCode = useRef(null);
    const [showCaptchCode, setShowCaptchCode] = useState("");
    
    
    const [show , setShow] = useState(false);

    const [clientVerify , setClientVerify] = useState(false);

    const submitForm = (e)=>
    {
        e.preventDefault();


        if(captchaCode.current.value === showCaptchCode)
        {
            if(email.current.value !=null && email.current.value !="" && password.current.value !=null && password.current.value !="")
            {
              
               axios.post('https://api-sms.we-matter.com/adminLogin',
               {
            //  axios.post('https://clientback.wematter.co.in/ClientLogin',
            //  {

                  // axios.post('https://api-sms.we-matter.com/adminLogin',
                  // {
                    adminEmail : email.current.value,
                    password : password.current.value })
    
                .then(response => {

      
                    if(response.data[1] === "loginSuccessfully")
                    {
                        sessionStorage.setItem("adminEmailID",response.data[0].email);
                        history.push("/HomePage") 
                        // if(response.data[0].clientVerify === "1")
                        // {
                        //   history.push("/Home") 
                        // }
                        // else
                        // {
                        //     setClientVerify(!clientVerify);
                        // }
  
                    }
                    else
                    {
                        toast.error('🦄 Email or Password Invalid !!', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });
                       // NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 3000);
        
                    }
            
                })
                .catch(error => {
                    console.log(error)
                })
    
            }
            else
            {
                toast.error('🦄 Email or Password Invalid !!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
              //  NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 2000);
            }
    
        }
        else
        {
            toast.error('🦄 Please Enter Valid Captcha Code !!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
          //  NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 2000);
        }
        



    }

        
    const handleModalPasss = ()=>
    {
      setShow(!show);
    }


    const handleClientVerify = ()=>
    {
      setClientVerify(!clientVerify);
    }
 
 


    useEffect(()=>{


       sessionStorage.clear();
       localStorage.clear();

        let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 7;
	    var randomstring = '';

        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        setShowCaptchCode(randomstring);

    },[])



    const handleCaptcha = () =>
    {
       
        let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 7;
	    var randomstring = '';

        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        setShowCaptchCode(randomstring);
    }





    return(

        <div>


           <div className="header">
                
                <div>
                <img src={titleLogo}  className="titleLogo" />
                <img src={secondImage}  className="secondLogo" />

                </div>
                
            </div>


<div className="login card">
  <h1>International Admin Login</h1>
  <form onSubmit={submitForm}>

  <input type="email" className="form-control col-sm-12" ref={email} placeholder="Company Email" id="email"   required /><br />
  <input type="password" className="form-control col-sm-12" ref={password} placeholder="Password" id="password"   required /><br />



  <div className="row">

        <div className="col-xl-5 col-md-12 col-sm-12">
        
        <img src={capcha} className="captchaImageLogin" />   <h4 className="catchaCode">{showCaptchCode}</h4><br />

        </div>

        <div className="col-xl-2 col-md-2 col-sm-2">

        <img src={refressCaptch} className="reFressCaptcha"  onClick={handleCaptcha} />

        </div>

        <div className="col-xl-5 col-md-5 col-sm-5">

            
        <input type="text" name="captchaCode"  ref={captchaCode}  required placeholder="Enter CAPTCHA code" className="form-control" autoComplete="off" />

        </div>





  </div>

 

    <div className="row">
      

        <div className="col-xl-12 col-md-12 col-sm-12">

     <input type="submit" name="commit" value="Login"  className="btn btn-primary"/>
        

        </div>


    </div>

    {/* <p className="remember_me">

   
    <p className="registButton"><Link to="/"  className="btn btn-primary">New Registration</Link>
    </p>

    </p> */}

   



    <div className="login-help">
  <p style={{color:"black"}}>Forgot your password?  <Link to="/forgotPassword"   ><span className="forgotPass">Click here to reset it.</span></Link></p>
 
</div>

        
  </form>
  
</div>




<ToastContainer />


<Modal size="md" show={clientVerify} onHide={handleClientVerify}>
            <Modal.Header closeButton>
              <Modal.Title>  </Modal.Title>
           </Modal.Header>
          <Modal.Body>
          <div className="container">
            <div className="row">

                <div className="col-xl-12 col-md-12 col-sm-12">

                         <h5>Please check your email to verify your email address before login.</h5>   

                </div>
        </div>
        </div>
                     
        
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="primary" onClick={handleClientVerify}>
            OK
          </Button> 
           
        </Modal.Footer>
      </Modal>




            
<Modal size="md" show={show} onHide={handleModalPasss}>
            <Modal.Header closeButton>
              <Modal.Title> Support </Modal.Title>
           </Modal.Header>
          <Modal.Body>
          <div className="container">
            <div className="row">

                <div className="col-xl-12 col-md-12 col-sm-12">
                        
                  
                <div>
                     

                     <p>Saurabh Jain</p>
                     <p>saurabh.jain@theother2thirds.com</p>
                     <p>+919820642246</p>
                     <p>+918080579009</p>

                 </div>

                </div>
        </div>
        </div>
                     
        
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="primary" onClick={handleModalPasss}>
            OK
          </Button> 
           
        </Modal.Footer>
      </Modal>




        </div>
    )
}


export default AdminLogin;