import React, { Component } from 'react';
import { Redirect ,Link} from 'react-router-dom';
import axios from 'axios';
import '../adminUser/css/RegistrationForm.css';
import '../adminUser/css/ClientLogin.css';
import '../adminUser/css/Home.css';
import SideBar from './sidebar/Sidebar';
import document from './Images/document.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import download from './Images/download.svg';
import DownloadLink from "react-download-link";
import {Button, Card,Modal} from 'react-bootstrap';

class SmsFileUpload extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            imageData :null,
            title : "",
            description : "",
            profileImg : document,
            data : [],
            logindIn : true,
            fileSizeCheck : true,
            passShow : false,
            checkFileUpload : false,
            show : false,
            redHome : true
            

        }




        let token = sessionStorage.getItem("companyName");

        if(token === null || token == undefined || token == "" )
        {
            this.state.logindIn = false;
        }


        /// for PaymentStatus

        if(sessionStorage.getItem("paymentStatus") == "0")
        {
            sessionStorage.setItem("proupData","On");
            sessionStorage.setItem("proupInfo","upload");
            this.setState({show : !this.state.show});
            this.state.redHome = false;
        } 



    }


    
    handleModelPayment = ()=>
    {
      this.setState({show : !this.state.show});
    }




    handleModalPasss = ()=>
    {
      this.setState({passShow : !this.state.passShow});
    }


    imageHandler = e => {

        const reader = new FileReader();
        // reader.onload = () => {
        //   if (reader.readyState === 2) {
        //     this.setState({ profileImg: reader.result });
        //   }
        // };

        if(e.target.files[0] !=null)
        {
            reader.readAsDataURL(e.target.files[0]);

            this.state.imageData = e.target.files[0];
        }

        // reader.readAsDataURL(e.target.files[0]);

        // this.state.imageData = e.target.files[0];


        // if(this.state.imageData.size >  10485760)
        // {

        //     this.state.fileSizeCheck = false;

        //     toast.error('🦄 Selected file size must be less then 10 MB !!!', {
        //         position: "top-right",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         });
              
        // }
        // else
        // {
        //     this.state.fileSizeCheck = true;
        // }


    };


    // replaceFile = (statusFile)=>
    // {

    //     if(this.state.imageData != null && this.state.imageData != undefined)
    //     {
    //         let formData = new FormData();

    //         formData.append("uploadFiles",this.state.imageData);
    //         formData.append("emailID",sessionStorage.getItem("emailID"));
    
    //       // axios.post('http://localhost:1337/fileUpload',formData)
    //      // axios.post('https://clientback.wematter.co.in/fileUpload',formData)
    //       axios.post('https://backend.we-matter.net/fileUpload',formData)
    //         .then((response)=>{
    
    //         if(response.data === "fileUploded")
    //         {

    //             if(this.state.checkFileUpload == true)
    //             {
                    
    //                 this.setState({passShow : !this.state.passShow});
                  
    //             }
    //             toast.success('🦄 File Uploded Successfully !!!', {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 });
                

    //             this.componentDidMount();
    //         }
    //         else
    //         { 
    //             toast.error('🦄 File Not Uploded !!!', {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 });
                
    //         }
    
    
    //         }).catch(function (error){
    
    //             alert(error);
    //         })
    //     }
    //     else
    //     {
    //         toast.error('🦄 Please select file !!!', {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             });
    //     }

    // }


    uploadImage = (e) =>
    {
        e.preventDefault();
        if(this.state.imageData != null && this.state.imageData != undefined)
        {
            let formData = new FormData();
            formData.append("uploadFiles",this.state.imageData);
            formData.append("companyName",sessionStorage.getItem("companyName"));

          axios.post('https://api-sms.we-matter.com/smsFileUpload',formData)
            .then((response)=>{
            if(response.data === "fileUplodedSuccessfully")
            {
                console.log("res",response.data)
                toast.success('🦄 File Uploded Successfully !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
                    //this.findUpdatedFile();
            }
            else
            { 
                toast.error('🦄 File Not Uploded !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
            }
    
    
            }).catch(function (error){
    
                alert(error);
            })
        }
        else
        {
            toast.error('🦄 Please select file !!!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
       

    }

    findUpdatedFile = ()=>
    {
        axios.post("https://api-sms.we-matter.com/findFile",{companyName: sessionStorage.getItem("companyName")})

        .then(response=>{

            if(response.data.length > 0)
            {
                this.setState({data : response.data});
            }
            else
            {
                this.setState({data : response.data});
            }
    
        }).catch(error=>{
    
          console.log(error)
    
        })
    }

    componentDidMount()
    {
        this.findUpdatedFile();
    }

    sendSMS = ()=>
    {
        axios.post("https://api-sms.we-matter.com/sendSMSWithCSV",{companyName: sessionStorage.getItem("companyName")})

        .then(response=>{

            if(response.data == "sendSuccessfully")
            {
                toast.success('🦄 SMS send successfully !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
            }
            else
            {
                toast.error('🦄 SMS not send !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
    
        }).catch(error=>{
    
          console.log(error)
    
        })
    }



    render(){

        if(this.state.logindIn === false)
        {
            return <Redirect to="/companyLogin" />
        }

        const { profileImg } = this.state;

        return(

            <div>

            <SideBar />
            <div className="container-fluid homeScreeAll">
          <div className="row">
     

          <div className="col-xl-12 col-md-12 col-sm-12  ">


          <div className="row">
            <div className="col-xl-6 col-md-12 col-sm-12">
            <div className="card downloadFiles">

            <h3 style={{color : "#000080"}}>Upload SMS File</h3>

            <hr className="hrTag"/>

            <form onSubmit={this.uploadImage}>
            <input type="file" className="btn btn-primary UploadButton" onChange={this.imageHandler}  accept=".xlsm,.xlsx,.xls,.csv" /> <br/>

            <img src={profileImg} alt="" id="img" className="img" /><br/>

            <input type='submit' value="Upload File" className="btn btn-primary UploadButton" />
            </form>
            </div>

            </div>


            <div className="col-xl-6 col-md-12 col-sm-12">


            <div className="card downloadFiles">

              <h3 style={{color : "#000080"}}>Uploded Files</h3>

              <hr className="hrTag"/>

                  <table className="table">

                      <thead>

                          <tr>
                              <td><b>File</b></td>
                              <td><b>Send SMS</b></td>
                              <td><b>Download</b></td>
                          </tr>
                      </thead>

                      {this.state.data.length >0 ? (
                        <tbody>
                        { this.state.data.map((user,index)=>
                              <tr>

                                  <td>{user.fileName}</td>
                                  <td><button className="btn btn-primary" onClick={this.sendSMS}>Send SMS</button></td>
                                  <td><Link to={user.filePath} target="_blank" download><img src={download} className="downLoadFile" /></Link></td>

                              </tr>
                          
                          )}
                          </tbody>
                      ) : (
                        <tbody>
                        <tr><td>Data not found</td></tr>
                        </tbody>
                      )}

                      
                  </table>
                      
                  
              </div>

          </div>


          </div>

 

            </div>

            </div>

            </div>


            

      <Modal size="md" show={this.state.passShow} onHide={this.handleModalPasss}>
            <Modal.Header closeButton>
              <Modal.Title>  </Modal.Title>
           </Modal.Header>
          <Modal.Body>
          <div className="container">
            <div className="row">

                <div className="col-xl-12 col-md-12 col-sm-12">
                            <h6>This file will replace the existing file.</h6>
                </div>
        </div>
        </div>
                     
        
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="primary" onClick={this.replaceFile}>
            OK
          </Button> 
           <Button onClick={this.handleModalPasss} variant="secondary">Cancel</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      </div>

          
        )
    }
}


export default SmsFileUpload;